import React from 'react';
import './Reset.css';
import { verifyCode } from 'Services/api';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


export default function ConfirmEmail({title="Email confirmed", subTitle="Thank for signing up for Rose Hospitality", }) {
  let { email, passcode } = useParams();
  useQuery(['verify', email, passcode],
    () => verifyCode({email: email, code: passcode})
  );

  const handleClose = (e) => {
    e.preventDefault();
    window.close();
  };

  return (
    <div className="public-form">
      <h2>{title}</h2>
      <p className="prompt">
        {subTitle}
      </p>
      <p><a href="/" onClick={handleClose}>Close</a></p>

    </div>
  );
  };
